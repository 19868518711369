
import { Component, Vue } from 'vue-property-decorator';

import CReportStats from '@/components/Report/CReportStats.vue';
import CReportData from '@/components/Report/CReportData.vue';
import CLoadingOverlay from '@/components/CLoadingOverlay.vue';
import CReportFilters from '@/components/Report/CReportFilters.vue';
import CReportFooter from '@/components/Report/CReportFooter.vue';
import CAlert from '@/components/CAlert.vue';

import { IChosenDateRange } from '@/interfaces';

import { dispatchFetchReport } from '@/store/actions';
import {
  readIsLoading,
  readCurrentReportDateRange,
  readIsSaved,
  readReportMode
} from '@/store/getters';

import {
  commitClearState,
  commitCurrentReportDateRange,
  commitIsSaved,
  commitReportMode,
} from '@/store/mutations';

@Component({
  components: {
    CReportStats,
    CReportData,
    CLoadingOverlay,
    CReportFilters,
    CReportFooter,
    CAlert,
  },
})
export default class ReportView extends Vue {
  public currentPath = this.$route.path;
  readonly mode = this.$route.params;

  public get isLoading(): boolean { return readIsLoading(this.$store); }
  public get dateRange(): IChosenDateRange { return readCurrentReportDateRange(this.$store); }
  public get isSaved(): boolean { return readIsSaved(this.$store); }

  private mounted() {
    const mode = readReportMode(this.$store);
    commitClearState(this.$store);
    commitReportMode(this.$store, mode);

    if (this.$route.path.includes('cached')) {
      commitReportMode(this.$store, 'getReportFromIA');   //default ReportMode = 'newReport'
      commitIsSaved(this.$store, true);
    }

    // if report url does not contain the 'cached' part,
    // we considering that the mode was pass from the DateRangeView
    // or use default ReportMode value
    const dateRange = this.$route.params['dateRange'];
    const dateRanges = dateRange.split('_');
    commitCurrentReportDateRange(this.$store, {
      dateFrom: dateRanges[0],
      dateTo: dateRanges[1],
    });
    dispatchFetchReport(this.$store);
  }

  public formatDateRange(): string {
    return this.dateRange.dateFrom + ' to ' + this.dateRange.dateTo;
  }
}
