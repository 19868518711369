import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VFooter,{attrs:{"padless":"","inset":"","app":""}},[_c('c-dialog',{attrs:{"dialogTitle":_vm.dialogTitle,"dialogText":_vm.dialogText,"show":_vm.showDialog},on:{"update:show":function($event){_vm.showDialog=$event}}}),_c(VCard,{staticClass:"flex",attrs:{"flat":"","tile":"","width":"100%","color":"grey lighten-5"}},[_c(VCardTitle,[(_vm.isSaved)?_c('p',{staticClass:"text-subtitle-1 ma-0"},[_c('strong',[_vm._v("Last updated")]),_vm._v(" - "+_vm._s(_vm.getLastUpdated())+" ")]):_c('p',{staticClass:"text-subtitle-1 ma-0"},[_c('strong',[_vm._v("This report is not saved")])]),_c(VSpacer),(!_vm.isSaved)?_c(VBtn,{attrs:{"color":"success","elevation":"0"},on:{"click":function($event){return _vm.saveReport('POST')}}},[_vm._v(" Save and share report ")]):_c(VBtn,{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.saveReport('GET')}}},[_vm._v(" Refresh report data ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }