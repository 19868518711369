import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.dialogTitle)+" ")]),_c(VCardText,{staticClass:"text-subtitle-1"},[_vm._v(" Share this report via the link: "),_c('a',{attrs:{"href":_vm.dialogText}},[_vm._v(_vm._s(_vm.dialogText))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.$emit('update:show', false)}}},[_vm._v(" Got it ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }