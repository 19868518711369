import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCol,{attrs:{"cols":"3"}},[_c(VSubheader,{staticClass:"text-subtitle-1 text--primary font-weight-medium"},[_vm._v(" "+_vm._s(_vm.boxName)+" ")]),_c(VList,{staticClass:"overflow-y-auto rounded",staticStyle:{"height":"200px"},attrs:{"dense":"","outlined":"","subheader":""}},[_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.selectedFilterRowId),callback:function ($$v) {_vm.selectedFilterRowId=$$v},expression:"selectedFilterRowId"}},_vm._l((_vm.sortedItems),function(item,i){return _c(VListItem,{key:i},[_vm._v(" "+_vm._s(item)+" "),_c(VListItemContent,[_c(VSpacer)],1),_c(VListItemAction,[_c(VChip,{attrs:{"small":"","color":"secondary"}},[_c('p',{staticClass:"ma-0 pa-0 font-weight-black"},[_vm._v(_vm._s(_vm.filterItems[item]))])])],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }