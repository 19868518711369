
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class CFilterBox extends Vue {
  @Prop() public boxName!: string;
  @Prop() public filterItems!: { [key: string]: number };

  public sortedItems: Array<string> = [];
  public selectedFilterRowId = '';

  @Watch('filterItems')
  sortItems() {
    this.sortedItems = this.sortFilteredByCount();
  }

  @Watch('selectedFilterRowId')
  emitSelectedFilter() {
    this.$emit('selectedFilter', this.sortedItems[parseInt(this.selectedFilterRowId)]);
  }

  sortFilteredByCount() {
    return Object.keys(this.filterItems).sort(
      (a, b) => this.filterItems[b] - this.filterItems[a],
    );
  }
}
