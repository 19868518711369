import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"mt-6"},[_c(VRow,{attrs:{"justify":"space-around"}},[_c('c-filter-box',{attrs:{"boxName":'Filter Boxed',"filterItems":_vm.filterVariants.boxedFilterVariants},on:{"selectedFilter":function($event){_vm.onFilterChange = {filterName: 'boxedFilter', value: $event}}}}),_c('c-filter-box',{attrs:{"boxName":'Filter Reasons',"filterItems":_vm.filterVariants.reasonVariants},on:{"selectedFilter":function($event){_vm.onFilterChange = {filterName: 'reasonFilter', value: $event}}}}),_c('c-filter-box',{attrs:{"boxName":'Filter Old pallet',"filterItems":_vm.filterVariants.oldPalletVariants},on:{"selectedFilter":function($event){_vm.onFilterChange = {filterName: 'oldPalletFilter', value: $event}}}})],1),_c(VRow,{staticClass:"mt-6",attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"2"}},[_c(VSelect,{attrs:{"outlined":"","dense":"","label":"Items per page","items":_vm.itemsPerPageVariants,"item-text":"name","item-value":"val","menu-props":{ offsetY: true }},on:{"change":function($event){_vm.itemsPerPageChange = $event}}})],1),_c(VCol,{attrs:{"cols":"2"}},[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Search"},on:{"input":function($event){_vm.onFilterChange = {filterName: 'searchField', value: $event}}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }