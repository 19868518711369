
import { IDataHeaders, IFilterState, IRejectedItem } from '@/interfaces';
import { readAllFiltersState, readItemsPerPage, readRejectedItems } from '@/store/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class CReportData extends Vue {
  public page = 1;
  public pageCount = 0;

  public boxedMapping: { [key: string]: string } = {
    Standart: '0',
    Boxed: '1',
  };

  public get reportItems(): Array<IRejectedItem> {
    return readRejectedItems(this.$store);
  }
  public get filtersState(): IFilterState {
    return readAllFiltersState(this.$store);
  }
  public get itemsPerPage(): number {
    return readItemsPerPage(this.$store);
  }

  public headers: Array<IDataHeaders> = [
    { text: 'Reject Item', sortable: true, value: 'reject_item' },
    { text: 'Reject file', value: 'reject_file', sortable: false },
    {
      text: 'Boxed',
      value: 'boxed',
      width: 60,
      filter: (colData: string): boolean =>
        this.applyFilter(colData, this.boxedMapping[this.filtersState.boxedFilter]),
    },
    { text: 'Selector', value: 'selector' },
    {
      text: 'Reason',
      value: 'reason',
      width: 120,
      filter: (colData: string): boolean =>
        this.applyFilter(colData, this.filtersState.reasonFilter),
    },
    { text: 'Scanning center', value: 'scanningcenter' },
    { text: 'Scanner', value: 'scanner' },
    { text: 'BoxID', value: 'boxid' },
    {
      text: 'Old pallet',
      value: 'old_pallet',
      width: 140,
      filter: (colData: string): boolean =>
        this.applyFilter(colData, this.filtersState.oldPalletFilter),
    },
    { text: 'Donation Item', value: 'donation_item', width: 100 },
    { text: 'Duplicates', value: 'dupe_identifiers', width: 140 },
    { text: 'Removed', value: 'removed', width: 60 },
  ];

  mounted() {
    this.headers.forEach((header: any) => {
      header.align = 'center';
      header.divider = '|';
    });
  }

  applyFilter(colData: string, userFilter: string): boolean {
    if (!userFilter) { return true }
    return userFilter == colData;
  }
}
