
import { Vue, Component, Watch } from 'vue-property-decorator';
import CDialog from '@/components/CDialog.vue';

import moment from 'moment';
import { api } from '@/api';
import {
  readCurrentReportDateRange,
  readIsSaved,
  readLastUpdatedTime,
} from '@/store/getters';
import {
  commitIsSaved,
  commitToogleAlert,
  commitToogleLoading,
} from '@/store/mutations';
import { Method } from 'axios';

@Component({
  components: {
    CDialog,
  },
})
export default class CReportFooter extends Vue {
  public dialogText = '';
  public dialogTitle = '';
  public showDialog = false;

  public get isSaved(): boolean {
    return readIsSaved(this.$store);
  }

  @Watch('showDialog')
  refreshPage() {
    if (!this.$route.path.includes('cached')) {
      this.$router.push({
        name: 'PreservedReportView',
        params: {
          dateRange: this.getStrDateRange(),
        },
      });
    } else {
      this.$router.go(0);
    }
  }

  public getStrDateRange(): string {
    const dateRange = readCurrentReportDateRange(this.$store);
    return dateRange.dateFrom + '_' + dateRange.dateTo;
  }

  public getLastUpdated(): string {
    const isoDateString = readLastUpdatedTime(this.$store);
    const lastUpdated = moment(isoDateString).format('MMMM D, YYYY, HH:mm');
    return lastUpdated;
  }

  // in case of POST method - report will be saved
  // in case of GET method - saved report will be updated
  saveReport(method: Method) {
    commitToogleLoading(this.$store, true);
    const resp = api.storeReportToIAItem(this.getStrDateRange(), method);
    resp
      .then((data) => {
        commitIsSaved(this.$store, true);
        commitToogleLoading(this.$store, false);

        this.dialogText = data.data.link;
        const msg =
          method == 'GET'
            ? 'Report is succesfully refreshed'
            : 'Report is succesfully saved on the IA servers';
        this.dialogTitle = msg;

        this.showDialog = true;
      })
      .catch((error) => {
        commitToogleLoading(this.$store, false);
        commitToogleAlert(this.$store, error);
      });
  }
}
