
import { IFilterVariants, IStatsTableData } from '@/interfaces';
import { readAllFilterVariants, readAllStatsTableItems, readFilterVariantsData } from '@/store/getters';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CReportStats extends Vue {
  public headers = [
    { text: 'Reason', align: 'center', sortable: true, value: 'reason', divider: '|' },
    { text: 'Count', align: 'center', value: 'count', divider: '|' },
    { text: '%', align: 'center', value: 'precentage', divider: '|' },
  ];

  getStatTableItems() {
    if (!this.filterVariants.reasonVariants) {
      return [];
    }
    return Object.keys(this.filterVariants.reasonVariants).map((reason) => ({
      reason: reason,
      count: this.filterVariants.reasonVariants[reason],
      precentage:
        ((this.filterVariants.reasonVariants[reason] / this.statsTableData.totalAttempted) * 100).toFixed(2) + '%',
    }));
  }

  public get filterVariants(): IFilterVariants {
    return readAllFilterVariants(this.$store);
  }

  public get statsTableData(): IStatsTableData {
    return readAllStatsTableItems(this.$store);
  }
}
