
import { Component, Vue } from 'vue-property-decorator';
import CFilterBox from '@/components/Report/CFilterBox.vue';

import { commitItemsPerPage, commitUpdateFilter } from '@/store/mutations';
import { readAllFiltersState, readAllFilterVariants, readFilterFieldState, readFilterVariantsData } from '@/store/getters';
import { IFilterVariants } from '@/interfaces';

@Component({
  components: {
    CFilterBox,
  },
})
export default class CReportFilters extends Vue {
  public itemsPerPageVariants: { val: number; name: string }[] = [
    { val: 10, name: '10' },
    { val: 25, name: '25' },
    { val: 50, name: '50' },
    { val: 100, name: '100' },
    { val: -1, name: 'All' },
  ];

  public get onFilterChange() {
    return {filterName: '', value: ''}
  }

  public set onFilterChange(change: {filterName: string, value: string}) {
    commitUpdateFilter(this.$store, {
      'filterName': change.filterName, value: change.value
    })
  }

  public set itemsPerPageChange(value: number) {
    commitItemsPerPage(this.$store, value);
  }

  public get itemsPerPageChange() {
    return 10;
  }

  public get filterVariants(): IFilterVariants {
    return readAllFilterVariants(this.$store);
  }
}
